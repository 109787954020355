<template>
  <div class="wrap">
    <div class="box-top">
       <!-- <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="dialogVisible = true">新增</el-button> -->
       <el-input prefix-icon="el-icon-search" v-model="username" placeholder="通过团队名字或个人账号搜索" @keydown.enter.native="handleSearch" clearable style="width: 20%;"></el-input>
    </div> 
    <!-- 列表 -->
    <commonTable ref="commonTable" :hasIndex="true" :dataSource="tableData" :columnsList="columns" :total="total" @currentChange="currentChange" @sizeChange="sizeChange">
      <template slot="company" slot-scope="{record}">
        {{ record.company ? record.company : record.school }}
      </template>
    </commonTable>

    <!-- <div style="flex: 1;">
      <el-table :data="tableData" border height="100%" ref="table" style="width: 100%">
        <el-table-column type="index" width="50" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="company" label="团队名字" align="center">
          <template slot-scope="scope">{{scope.row.company?scope.row.company:scope.row.school}}</template>
        </el-table-column>
        <el-table-column prop="count" label="关注度" align="center">
        </el-table-column>
        <el-table-column prop="nickname" label="昵称" align="center">
        </el-table-column>
        <el-table-column prop="work" label="职业" align="center">
        </el-table-column> -->

        <!-- <el-table-column prop="options" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="editButton(scope.row)" type="text" style="color: #00788b;">编辑</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button @click="del(scope.row)" type="text" style="color: #b51c1b;">删除</el-button>
        </template>
        </el-table-column> -->

      <!-- </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination> -->
  </div>
</template>

<script>
import { listFollows } from '../../api/rank';
import commonTable from '../../components/commonTable.vue';
import { myMixins } from '../../mixins/myMixins';
export default {
  name: 'userManage',
  mixins: [myMixins],
  data() {
    return {
      username: '',
      total: 0,
      pageSize: 10,
      pageNo: 1,
      tableData: [],
      columns: [
        {
          title: '团队名字',
          key: 'company',
          align: 'center',
          scopedSlots: {
            columnSlot: 'company'
          }
        },
        {
          title: '关注度',
          key: 'count',
          align: 'center',
        },
        {
          title: '昵称',
          key: 'nickname',
          align: 'center',
        },
        {
          title: '职业',
          key: 'work',
          align: 'center',
        }
      ]
    }
  },
  mounted(){
    this.loadData();
  },
  components: {
    commonTable
  },
  methods: {
    loadData(){
      let param = {
        username: this.username,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      listFollows(param).then(res=>{
        if(res.success) {
          this.tableData = res.result.data;
          this.total = res.result.count;
          this.$nextTick(() => {
            this.$refs.commonTable.$refs.table.doLayout();
          })
        }
      })
    },
    // handleSizeChange(val) {
    //   this.pageSize = val;
    //   this.loadData();
    // },
    // handleCurrentChange(val) {
    //   this.pageNo = val;
    //   this.loadData();
    // },
    handleSearch(){
      this.pageNo = 1;
      this.loadData();
    }
  }
}
</script>

<style scoped lang="less">
  @import '../../assets/css/commonMenu.less';
</style>